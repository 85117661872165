/* You can add global styles to this file, and also import other style files */
body {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.relative-container {
  position: relative; /* Đặt container này làm gốc để nút bám theo */
  height: 100vh;
}
@media only screen and (min-width: 900px) {
  .baby-col-desktop {
    box-shadow: 0px 1px 12px rgba(21, 21, 21, 0.08);
  }
  .detail-post > .content {
    font-size: 22px !important;
  }
  .detail-post > .content p {
    font-size: 22px !important;
  }
  .detail-post > .title {
    font-size: 36px !important;
  }
  .menu img {
    height: 35px !important;
  }
  .nav-bar {
    margin-left: 35px !important;
  }
  .detail-post > .content h1 {
    font-size: 24px !important;
    line-height: 40px !important;
  }
  .detail-post > .content h2 {
    font-size: 24px !important;
    line-height: 40px !important;
  }
}
.modal {
  z-index: 11000;
}
.detail-post > .content img {
  width: 100%;
}
.detail-post > .content h1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.detail-post > .content h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.post-img img {
  border-radius: 10px;
  /* max-height: 170px; */
}
